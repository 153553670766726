<template>
  <div>
    <div class="table-content row-data" style="margin-bottom: 20px">
      <div style="margin-bottom: 20px">
        <el-select
            clearable
            @change="findAllData"
            style="width: 160px"
            v-model="dataSearch.statusAction"
            placeholder="Trạng thái"
        >
          <el-option
              v-for="(item,index) in listStatusAction"
              :key="index"
              :value="item.key"
              :label="item.value"
          ></el-option>
        </el-select>
        <el-select
            @change="findAllData"
            style="width: 120px; margin-left: 10px"
            class=""
            v-model="dataSearch.delActive"
        >
          <el-option
              v-for="(item,index) in listDelActive"
              :key="index"
              :value="item.key"
              :label="item.value"
          ></el-option>
        </el-select>
        <el-input
            @clear="findAllData"
            clearable
            style="width: 240px; margin-left: 10px"
            placeholder="Nhập tên hoạt động"
            v-model="dataSearch.nameAction"
            @keyup.enter.native="findAllData"
        >
          <el-button
              slot="append"
              icon="el-icon-search"
              @click="findAllData"
          ></el-button>
        </el-input>
      </div>
      <el-table
          :empty-text="textTable"
          v-loading="loadingData"
          :element-loading-text="$tableLoading"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255,255,255, 0)"
          :data="listHistoryActionAdmin"
          highlight-current-row
          :header-cell-style="tableHeaderColor"
          @selection-change="handleSelectionChange"
          :max-height="$tableMaxHeight"
          border
      >
        <el-table-column
            fixed
            type="selection"
            width="50"
            align="center"
        ></el-table-column>
        <el-table-column
            fixed
            type="index"
            label="STT"
            width="50"
            align="center"
        ></el-table-column>
        <el-table-column fixed prop="nameAction" min-width="170" label="Tên hoạt động">
          <template slot-scope="scope">
            {{ scope.row.nameAction}}
          </template>
        </el-table-column>
        <el-table-column fixed prop="nameAction" min-width="100" label="Mã hoạt động">
          <template slot-scope="scope">
            {{ scope.row.codeAction}}
          </template>
        </el-table-column>
        <el-table-column label="Trạng thái hoạt động" prop="statusAction" min-width="120">
          <template slot-scope="scope">
           <span v-if="scope.row.statusAction === true">Đang hoạt động</span>
           <span v-else style="color: #F56C6C">Ngừng hoạt động</span>
          </template>
        </el-table-column>
        <el-table-column prop="typeAction" label="Loại" min-width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.typeAction}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Tác vụ"
            align="center"
            fixed="right"
            width="300px"
        >
          <template slot-scope="scope">
            <el-button
                size="medium"
                type="primary"
                @click="handleDetail(scope.row)"
            >Lịch sử</el-button>

            <el-button
                :disabled="scope.row.delActive === false"
                style="margin-left: 20px"
                size="medium"
                type="success"
                @click="handleUpdate(scope.row)"
            >Sửa</el-button>

            <el-button
                style="margin-left: 20px"
                :disabled="scope.row.statusAction === true || scope.row.delActive === false"
                size="medium"
                type="danger"
                @click="handleDelete(scope.row)"
            >Xóa</el-button>
          </template>

        </el-table-column>
      </el-table>
    </div>
    <div class="right-align" style="margin-top: 20px">
      <el-dropdown @command="handleCommand" :disabled="dataSearch.delActive === false">
        <el-button class="button-over" type="success" :disabled="dataSearch.delActive === false">
          Tác vụ
          <i class="el-icon-caret-bottom"/>
        </el-button>
        <el-dropdown-menu class="el-dropdown-menu-container">
          <el-dropdown-item command="createAction">Thêm hoạt động</el-dropdown-item>
          <el-dropdown-item command="activeAction" v-if="showAction">Kích hoạt</el-dropdown-item>
          <el-dropdown-item command="no-activeAction" v-if="showNoAction">Hủy kích hoạt</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <CreateHistoryActionDialog
      :listCodeAction = "listCodeAction"
      :isCreateHistoryAction = "isCreateHistoryAction"
      @close = "handleCloseCreateHistoryActionDialog"
    />
    <UpdateHistoryActionDialog
        :idHistory = "idHistory"
        :isUpdateHistoryAction = "isUpdateHistoryAction"
        @close = "handleCloseUpdateHistoryActionDialog"
    />
    <DetailHistoryActionDialog
        :isDetailHistoryAction = "isDetailHistoryAction"
        :idHistory = "idHistory"
        :codeAction = "codeAction"
        @close = "handleDetailHistoryActionDialog"
    />
  </div>
</template>

<script>
import HistoryActionMaster from "@/services/AdminService/HistoryActionMaster";
import CreateHistoryActionDialog from "@/views/master/other/CreateHistoryActionDialog.vue";
import UpdateHistoryActionDialog from "@/views/master/other/UpdateHistoryActionDialog.vue";
import DetailHistoryActionDialog from "@/views/master/other/DetailHistoryActionDialog.vue";

export default {
  name: "ManageOperationHistoryS3",
  components: {DetailHistoryActionDialog, UpdateHistoryActionDialog, CreateHistoryActionDialog,},
  data() {
    return {
      listHistoryActionAdmin: [],
      multipleSelection: [],
      responseFilterList: "",
      loadingData: true,
      textTable: "",
      isCreateHistoryAction: false,
      isUpdateHistoryAction: false,
      isDetailHistoryAction: false,
      idHistory: 0,
      codeAction:"",
      dataSearch: {
        statusAction: null,
        delActive: true,
        nameAction: "",
      },
      showAction: true,
      showNoAction: true,
      listCodeAction: [],
      listStatusAction: [
        {key: true, value: "Đang hoạt động"},
        {key: false, value: "Ngừng hoạt động"},
      ],
      listDelActive: [
        {key: true, value: "Chưa xóa"},
        {key: false, value: "Đã xóa"},
      ]
    }
  },
  created() {
    this.findAllData()
  },
  methods: {
    findAllData(){
      this.loadingData = true;
      this.textTable = "";
        HistoryActionMaster.findAllHistoryActionAdmin(this.dataSearch.delActive,this.dataSearch.statusAction,this.dataSearch.nameAction).then((res) =>{
          this.listHistoryActionAdmin = res.data.data

        }).catch((err) => {
          this.listHistoryActionAdmin = null;
          console.log(err);
        }).finally(() =>{
          if (this.listHistoryActionAdmin.length === 0){
            this.textTable = this.$tableEmpty;
          }
          this.loadingData = false;
        })
    },
    handleCommand(command){
      if (command === "createAction"){
        for (let i = 0; i < this.listHistoryActionAdmin.length; i++) {
          this.listCodeAction.push(this.listHistoryActionAdmin[i].codeAction)
        }
        this.isCreateHistoryAction = true;
      }
      if (command === "activeAction"){
        this.handleActiveAction();
      }
      if (command === "no-activeAction"){
        this.handleNoActiveAction();
      }
    },
    handleActiveAction(){
      if (this.multipleSelection.length === 0){
        this.$message({
          message: "Bạn chọn hoạt động nào!",
          type: "error",
        });
      }else {
        // let listId = this.multipleSelection.map(number => number.toString());
        let listId = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          listId.push(this.multipleSelection[i].id)
        }
        let data = {
          listId: listId,
        }
        this.$confirm(
            "Bạn có chắc chắn muốn kích hoạt đã chọn?",
            "Thông báo!",
            {
              distinguishCancelAndClose: true,
              confirmButtonText: "Có",
              closeOnClickModal: false,
              cancelButtonText: "Không",
            }
        ).then(() => {
        HistoryActionMaster.activeHistoryActionAdmin(data)
            .then((res) => {
              if (res.data) {
                this.$message({
                  message: "Kích hoạt thành công",
                  type: "success",
                });
                this.findAllData();
              } else {
                this.$message({
                  message: "Kích hoạt thất bại",
                  type: "error",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            }).finally(() =>{
              this.findAllData()
        })
        });
      }
    },
    handleNoActiveAction(){
      if (this.multipleSelection.length === 0){
        this.$message({
          message: "Bạn chọn hoạt động nào!",
          type: "error",
        });
      }else {
        // let listId = this.multipleSelection.map(number => number.toString());
        let listId = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          listId.push(this.multipleSelection[i].id)
        }
        let data = {
          listId: listId,
        }
        this.$confirm(
            "Bạn có chắc chắn muốn hủy kích hoạt đã chọn?",
            "Thông báo!",
            {
              distinguishCancelAndClose: true,
              confirmButtonText: "Có",
              closeOnClickModal: false,
              cancelButtonText: "Không",
            }
        ).then(() => {
        HistoryActionMaster.noActiveHistoryActionAdmin(data)
            .then((res) => {
              if (res.data) {
                this.$message({
                  message: "Hủy thành công",
                  type: "success",
                });
                this.findAllData();
              } else {
                this.$message({
                  message: "Hủy thất bại",
                  type: "error",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            }).finally(() =>{
              this.findAllData()
        })
        });
      }
    },
    handleDetail(row){
      this.idHistory = row.id;
      this.codeAction = row.codeAction
      console.log(this.idHistory)
      this.isDetailHistoryAction = true;
    },
    handleUpdate(row){
      this.idHistory = row.id;
      this.isUpdateHistoryAction = true;
    },

    handleDelete(row){
      this.$confirm(
          "Bạn có chắc chắn muốn xóa hoạt đã chọn?",
          "Thông báo!",
          {
            distinguishCancelAndClose: true,
            confirmButtonText: "Có",
            closeOnClickModal: false,
            cancelButtonText: "Không",
          }
      ).then(() => {
        HistoryActionMaster.deleteHistoryActionAdmin(row.id)
            .then((res) => {
              if (res.data) {
                this.$message({
                  message: "Xóa hoạt động thành công",
                  type: "success",
                });
                this.findAllData();
              } else {
                this.$message({
                  message: "Xóa hoạt thất bại",
                  type: "error",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
      });
    },
    handleCloseUpdateHistoryActionDialog(){
      this.isUpdateHistoryAction = false;
      this.findAllData()
    },
    handleCloseCreateHistoryActionDialog(){
      this.isCreateHistoryAction = false;
      this.findAllData()
    },
    handleDetailHistoryActionDialog(){
      this.isDetailHistoryAction = false;
      this.findAllData()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let checkStatusAtion = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
          checkStatusAtion.push(this.multipleSelection[i].statusAction)
      }
      let trueCount = 0;
      let falseCount = 0;
      for (let  i = 0; i< checkStatusAtion.length; i++) {
        if (checkStatusAtion[i]) {
          trueCount++;
        } else {
          falseCount++;
        }
      }
      if (trueCount > 0 && falseCount > 0) {
        this.showAction = false
        this.showNoAction = false
      }else if(trueCount > 0 && falseCount === 0){
        this.showAction = false
        this.showNoAction = true
      }else if(trueCount === 0 && falseCount > 0){
        this.showAction = true
        this.showNoAction = false
      }
        else {
        this.showAction = true
        this.showNoAction = true
      }
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold";
    },
  }
}
</script>

<style scoped>
.right-align {
  text-align: right;
}
</style>