<template>
  <div>
    <el-dialog
        :title="'Tạo hoạt động'"
        :visible.sync="isCreateHistoryAction"
        width="600px"
        :before-close="handleClose"
        top="5vh"
        :close="handleClose"
    >
      <el-form
          :model="formData"
          :rules="rules" ref="formData"
          label-position="left"
          label-width="120px">
        <el-form-item label="Tên hoạt động" prop="nameAction">
          <el-input v-model="formData.nameAction"></el-input>
        </el-form-item>
        <el-form-item label="Loại" prop="typeAction">
          <el-input v-model="formData.typeAction"></el-input>
        </el-form-item>
        <el-form-item label="Mã hoạt động" prop="codeAction">
          <el-input v-model="formData.codeAction"></el-input>
        </el-form-item>
      </el-form>
      <div class="buttons">
        <el-button
            :loading="loading"
            size="medium"
            type="success"
            style="margin-right: 10px"
            @click="handleUpload()"
        >
          Lưu
        </el-button>
        <el-button size="medium" type="danger" @click="handleClose">
          <i class="el-icon-circle-close"/> Đóng
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
 import HistoryActionMaster from "@/services/AdminService/HistoryActionMaster";

 export default {
   name:"CreateHistoryActionDialog",
   data(){
     return{
       formData:{
         nameAction: "",
         typeAction: "",
         codeAction: ""
       },
       loading: false,
       rules: {
         nameAction: [
           { required: true, message: 'Không được để trống', trigger: 'change' }
         ],
         typeAction: [
           { required: true, message: 'Không được để trống', trigger: 'change' }
         ],
         codeAction: [
           { required: true, message: 'Không được để trống', trigger: 'change' }
         ],
       }
     }
   },
   props: {
     isCreateHistoryAction: {
       type: Boolean,
       default: false
     },
     listCodeAction:{
       type: Array,
       default: () => []
     }
   },

   methods: {
     handleClose(){
       this.$refs['formData'].resetFields();
       this.$emit("close");
     },
     handleUpload(){
       this.$refs['formData'].validate((valid) => {
         if (valid) {
         this.loading = true
         let check = false;
           console.log(this.$props.listCodeAction)
           console.log(this.formData.codeAction)
            if (this.$props.listCodeAction.includes(this.formData.codeAction.replace(/\s/g, ''))){
              check = true;
            }
           console.log(check)
           if (check){
             this.$message({
               message: `Mã '${this.formData.codeAction.replace(/\s/g, '')}' đã tổn tại!`,
               type: "error",
             });
             this.loading = false
           }else {
             HistoryActionMaster.createHistoryActionAdmin(this.formData).then((res) => {
               if (res.data) {
                 this.$message({
                   message: "Tạo mới hoạt động thành công",
                   type: "success",
                 });
               } else {
                 this.$message({
                   message: "Tạo mới hoạt động thất bại",
                   type: "error",
                 });
               }
               this.loading = false;
               this.handleClose();
             }).catch((err) => {
               this.loading = false;
               this.$message({
                 message: err.response.data.message,
                 type: "error",
               });
             })
           }
         }
       });
     }
   }

 }
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 10px;
}
</style>