<template>
  <div>
    <el-dialog
        :title="'Lịch sử hoạt động'"
        :visible.sync="isDetailHistoryAction"
        width="1500px"
        :before-close="handleClose"
        top="5vh"
        :close="handleClose"
        @open = "handleOpen"
    >
      <el-table
          :empty-text="textTable"
          v-loading="loadingData"
          :element-loading-text="$tableLoading"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255,255,255, 0)"
          :data="listDetailHistoryAction"
          highlight-current-row
          :header-cell-style="tableHeaderColor"
          :max-height="$tableMaxHeight"
          :summary-method="getSummaries"
          :show-summary="shouldShowSummary"
          border
      >
        <el-table-column
            fixed
            type="index"
            label="STT"
            width="50"
            align="center"
        ></el-table-column>
        <el-table-column label="Thời gian thực hiện" align="center">
            <el-table-column  width="150" label="Ngày bắt đầu" align="center" header>
              <template slot-scope="scope">
                {{ formatDate(scope.row.createdDate)}}
              </template>
            </el-table-column>
            <el-table-column  width="150" label="Ngày kết thúc" align="center" header>
              <template slot-scope="scope">
                <span v-if="scope.row.lastModifieDate === scope.row.createdDate" style="color: #409EFF">Đến nay</span>
                <span v-else> {{ formatDate(scope.row.lastModifieDate)}}</span>
              </template>
            </el-table-column>
        </el-table-column>
        <el-table-column label="Thời gian được chọn" align="center">
              <el-table-column   width="150" label="Ngày bắt đầu" align="center" header>
                <template slot-scope="scope">
                  <span v-if="scope.row.startDate !== null">{{ formatDate(scope.row.startDate)}}</span>
                </template>
              </el-table-column>
              <el-table-column   width="150" label="Ngày kết thúc" align="center" header>
                <template slot-scope="scope">
                  <span v-if="scope.row.endDate !== null">{{ formatDate(scope.row.endDate)}}</span>
                </template>
              </el-table-column>
        </el-table-column>
        <el-table-column label="Trạng thái" width="320">
          <template slot-scope="scope">
            <span v-if="scope.row.delActive === true" >{{scope.row.statusAction}}</span>
            <span  v-else style="color: #F56C6C">{{scope.row.statusAction}}</span>
          </template>
        </el-table-column>
        <el-table-column  label="Mô tả">
          <template slot-scope="scope">
            {{ scope.row.description}}
          </template>
        </el-table-column>
        <el-table-column  label="Kích thước (MB)" align="right" v-if="codeAction === 'SCANSERVER' || codeAction === 'UPLOADS3'">
          <template slot-scope="scope">
            {{ scope.row.sizeFiles.toFixed(2)}}
          </template>
        </el-table-column>
      </el-table>

      <div class="buttons">
        <el-button size="medium" type="danger" @click="handleClose">
          <i class="el-icon-circle-close"/> Đóng
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HistoryActionMaster from "@/services/AdminService/HistoryActionMaster";

export default {
  name:"DetailHistoryActionDialog",
  data(){
    return{
      loadingData: true,
      listDetailHistoryAction: [],
      textTable: "",
      formData:{
        nameAction: "",
        typeAction: ""
      },
      shouldShowSummary: false,
      loading: false,
      rules: {
        nameAction: [
          { required: true, message: 'Không được để trống', trigger: 'change' }
        ],
        typeAction: [
          { required: true, message: 'Không được để trống', trigger: 'change' }
        ],
      }
    }
  },
  computed: {
    // Biến này có thể được tính toán dựa trên các điều kiện của bạn
    shouldShowSummaryBasedOnCondition() {
      return this.$props.codeAction === 'SCANSERVER' || this.$props.codeAction === 'UPLOADS3';
    }
  },
  watch: {
    shouldShowSummaryBasedOnCondition(newVal) {
      // Cập nhật giá trị của `shouldShowSummary` khi có thay đổi trong điều kiện
      this.shouldShowSummary = newVal;
    }
  },
  props: {
    isDetailHistoryAction: {
      type: Boolean,
      default: false
    },
    idHistory:{
      type: Number,
      default: () => 0,
    },
    codeAction:{
      type: String,
      default: () => '',
    }
  },
  methods: {
    formatDate(dateTimeString) {
      const dateTime = new Date(dateTimeString);
      const hours = dateTime.getHours().toString().padStart(2, '0');
      const minutes = dateTime.getMinutes().toString().padStart(2, '0');
      const seconds = dateTime.getSeconds().toString().padStart(2, '0');
      const day = dateTime.getDate().toString().padStart(2, '0');
      const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
      const year = dateTime.getFullYear();

      return `${hours}:${minutes}:${seconds} - ${day}-${month}-${year}`;
    },
    handleOpen(){
      HistoryActionMaster.findAllHistoryActionSaveAdminById(this.$props.idHistory).then((res) =>{
        this.listDetailHistoryAction = res.data.data
      }).catch((err) => {
        this.listDetailHistoryAction = null;
        console.log(err);
      }).finally(() =>{
        if (this.listDetailHistoryAction.length === 0){
          this.textTable = this.$tableEmpty;
        }
        this.loadingData = false;
      })

    },
    handleClose(){
      this.$emit("close");
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold";
    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      if (this.$props.codeAction === 'SCANSERVER' || this.$props.codeAction === 'UPLOADS3'){
        console.log("123456")
        this.shouldShowSummary = true
        columns.forEach((column, index) => {
          if (index === 6) {
            sums[index] = "Tổng cộng";
            return;
          }
          // Kiểm tra nếu là cột "Kích thước (MB)"
          if (column.label === 'Kích thước (MB)') {
            const values = data.map(item => {
              // Parse giá trị số từ chuỗi và chuyển đổi sang megabyte
              return parseFloat(item.sizeFiles) || 0;
            });
            // Tính tổng giá trị kích thước
            const totalSize = values.reduce((prev, curr) => prev + curr, 0);
            sums[index] = totalSize.toFixed(2); // Làm tròn đến 2 chữ số sau dấu thập phân
          } else {
            const values = data.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
            } else {
              sums[index] = '';
            }
          }
        });

        return sums;
      }else {
        this.shouldShowSummary = false
      }

    },
  }

}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 10px;
}
/deep/ .el-table__footer-wrapper tbody td.el-table__cell, .el-table__header-wrapper tbody td.el-table__cell {
  font-weight: bold;
  background-color: #F5F7FA;
  color: #0957f4;
}
</style>