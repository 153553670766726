<template>
  <div>
    <el-dialog
        :title="'Sửa hoạt động'"
        :visible.sync="isUpdateHistoryAction"
        width="600px"
        :before-close="handleClose"
        top="5vh"
        :close="handleClose"
        @open = "handleOpen"
    >
      <el-form
          :model="formData"
          :rules="rules" ref="formData"
          label-position="left"
          label-width="120px">
        <el-form-item label="Tên hoạt động" prop="nameAction">
          <el-input v-model="formData.nameAction"></el-input>
        </el-form-item>
        <el-form-item label="Loại" prop="typeAction">
          <el-input v-model="formData.typeAction"></el-input>
        </el-form-item>
        <el-form-item label="Mã hoạt động" prop="codeAction">
          <el-input v-model="formData.codeAction" disabled></el-input>
        </el-form-item>
      </el-form>
      <div class="buttons">
        <el-button
            :loading="loading"
            size="medium"
            type="success"
            style="margin-right: 10px"
            @click="handleUpload()"
        >
          Sửa
        </el-button>
        <el-button size="medium" type="danger" @click="handleClose">
          <i class="el-icon-circle-close"/> Đóng
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HistoryActionMaster from "@/services/AdminService/HistoryActionMaster";

export default {
  name:"UpdateHistoryActionDialog",
  data(){
    return{
      formData:{
        nameAction: "",
        typeAction: "",
        codeAction: ""
      },
      loading: false,
      rules: {
        nameAction: [
          { required: true, message: 'Không được để trống', trigger: 'change' }
        ],
        typeAction: [
          { required: true, message: 'Không được để trống', trigger: 'change' }
        ],
        codeAction: [
          { required: true, message: 'Không được để trống', trigger: 'change' }
        ],
      }
    }
  },
  props: {
    isUpdateHistoryAction: {
      type: Boolean,
      default: false
    },
    idHistory:{
      type: Number,
      default: () => 0,
    }
  },

  methods: {
    handleOpen(){
      HistoryActionMaster.findHistoryActionAdminById(this.$props.idHistory).then((res) =>{
        this.formData = res.data.data
      })

    },
    handleClose(){
      this.$refs['formData'].resetFields();
      this.$emit("close");
    },
    handleUpload(){
      this.$refs['formData'].validate((valid) => {
        if (valid) {
          console.log(this.formData)
          console.log(this.formData.typeAction)
          this.loading = true
          HistoryActionMaster.updateHistoryActionAdmin(this.formData).then((res) =>{
            if (res.data){
              this.$message({
                message: "Sửa hoạt động thành công",
                type: "success",
              });
            }else {
              this.$message({
                message: "Sửa hoạt động thất bại",
                type: "error",
              });
            }
            this.loading = false;
            this.handleClose();
          }).catch((err) => {
            this.loading = false;
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          })
        }
      });
    }
  }

}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 10px;
}
</style>