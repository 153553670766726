import http from "@/http-common";
class HistoryActionMaster {
    findAllHistoryActionAdmin(delActive, statusAction, nameAction){
        return http.get(`/history-action-master`,{params: {delActive, statusAction, nameAction}})
    }

    createHistoryActionAdmin(data){
        return http.post(`/history-action-master/create`,data)
    }
    findHistoryActionAdminById(id){
        return http.get(`/history-action-master/${id}`)
    }
    updateHistoryActionAdmin(data){
        return http.post(`/history-action-master/update`,data)
    }

    findAllHistoryActionSaveAdminById(id){
        return http.get(`/history-action-master/detail/${id}`)
    }

    deleteHistoryActionAdmin(id){
        return http.delete(`/history-action-master/delete/${id}`)
    }

    activeHistoryActionAdmin(data){
        return http.post(`/history-action-master/active`,data)
    }

    noActiveHistoryActionAdmin(data){
        return http.post(`/history-action-master/no-active`,data)
    }
}
export default new HistoryActionMaster();