var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Lịch sử hoạt động",
            visible: _vm.isDetailHistoryAction,
            width: "1500px",
            "before-close": _vm.handleClose,
            top: "5vh",
            close: _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isDetailHistoryAction = $event
            },
            open: _vm.handleOpen,
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingData,
                  expression: "loadingData",
                },
              ],
              attrs: {
                "empty-text": _vm.textTable,
                "element-loading-text": _vm.$tableLoading,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255, 0)",
                data: _vm.listDetailHistoryAction,
                "highlight-current-row": "",
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeight,
                "summary-method": _vm.getSummaries,
                "show-summary": _vm.shouldShowSummary,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c(
                "el-table-column",
                { attrs: { label: "Thời gian thực hiện", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      width: "150",
                      label: "Ngày bắt đầu",
                      align: "center",
                      header: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.formatDate(scope.row.createdDate)) +
                                "\n            "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "150",
                      label: "Ngày kết thúc",
                      align: "center",
                      header: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.lastModifieDate === scope.row.createdDate
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#409EFF" } },
                                  [_vm._v("Đến nay")]
                                )
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDate(
                                          scope.row.lastModifieDate
                                        )
                                      )
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "Thời gian được chọn", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      width: "150",
                      label: "Ngày bắt đầu",
                      align: "center",
                      header: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.startDate !== null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.formatDate(scope.row.startDate))
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "150",
                      label: "Ngày kết thúc",
                      align: "center",
                      header: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.endDate !== null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.formatDate(scope.row.endDate))
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-table-column", {
                attrs: { label: "Trạng thái", width: "320" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.delActive === true
                          ? _c("span", [_vm._v(_vm._s(scope.row.statusAction))])
                          : _c("span", { staticStyle: { color: "#F56C6C" } }, [
                              _vm._v(_vm._s(scope.row.statusAction)),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Mô tả" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.description) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.codeAction === "SCANSERVER" || _vm.codeAction === "UPLOADS3"
                ? _c("el-table-column", {
                    attrs: { label: "Kích thước (MB)", align: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.sizeFiles.toFixed(2)) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2300216061
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "danger" },
                  on: { click: _vm.handleClose },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _vm._v(" Đóng\n      "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }